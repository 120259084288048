<template>
    <div class="about">
        <p class="" v-if="aboutInfo" v-html="`${aboutInfo.contentText}`"></p>
<!--        <img src="@/assets/img/about-img.jpg" alt="">-->
    </div>
</template>

<script>
import {websiteConfigDetail} from "@/api/home";

export default {
    name: "about",
    data(){
      return{
          aboutInfo:'',
      }
    },
    mounted() {
        this.getwebsiteConfigDetail()
    },
    methods:{
        //  -------------------------接口请求
        getwebsiteConfigDetail(){ // 获取基础设置 关于一方
            websiteConfigDetail({tag:'about_yifang'}).then((res) => {
                if (res.data.code==200) {
                    this.aboutInfo=res.data.data
                    this.aboutInfo.contentText=this.$utils.showHtml(this.aboutInfo.contentText)
                }else{

                }

            })
        },

    },
}
</script>

<style scoped>

</style>
