<template>
    <div class="m-pop">
        <div class="pop-con">
            <div class="title">
                <h3>投递简历</h3>
                <div class="close" @click="$emit('close')"></div>
            </div>
            <div class="inner-con">
                <div class="job-list">
                    <div class="item" v-for="(item,index) in jobList" :key="index">
                        <h3>{{ item.title }}</h3>
                        <div class="g-btns">
                            <el-upload
                                class="upload-demo"
                                action='/api/blade-resource/oss/endpoint/put-file'
                                :before-upload="handleBeforeUpload"
                                :on-preview="handlePreview"
                                :on-remove="handleRemove"
                                :before-remove="beforeRemove"
                                :limit="10000"
                                :multiple="false"
                                :headers="uploadHeaders"
                                :on-exceed="handleExceed"
                                :on-success="uploadSuccess"
                                :show-file-list="false"
                                :file-list="fileList">
                                <el-button class="g-btn white" size="small" type="primary" @click="Uploadresume(item,index)">上传简历</el-button>
                            </el-upload>
<!--                            <div class="g-btn white" @click="Uploadresume(item,index)">上传简历</div>-->
                            <div class="g-btn mal-10" @click="submitresume(item,index)">提交</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {jobPostList,jobResumeSave} from "@/api/CMSmodule";
import {} from "@/api/public";


export default {
    name: "jobs",
    data(){
        return{
            jobList:[],
            uploadHeaders:{
                "blade-auth":localStorage.getItem('Token'),
                'Authorization':"Basic c2FiZXI6c2FiZXJfc2VjcmV0",
                'Tenant-Id':'000000'
            },
            fileList:[],
            noteIndex:'', //当前点击的简历
        }
    },
    mounted() {

        this.getjobPostList()
    },
    methods:{
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        // 文件上传前触发
        handleBeforeUpload(file){
// 拿到文件后缀名
            const fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
            console.log('文件上传类型', fileType)
            // 当然拉我的需求是只需要图片和pdf，大家有需要可以在此处扩展
            const isDoc = fileType === 'doc'
            const isDocx = fileType === 'docx'
            const isPdf = fileType === 'pdf'
            // const isLt1M = file.size / 1024 / 1024 < 1;
            // 根据后缀名判断文件类型
            if (!isDoc && !isDocx && !isPdf) {
                this.$message.error('只能上传文档和pdf格式的文件！')
                return false
            }
            return isDoc || isDocx || isPdf
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        // 删除文件钩子
        beforeRemove(file, fileList) {
            // return this.$confirm(`确定移除 ${ file.name }？`);
        },
        Uploadresume(item,index){ // 上传简历
            console.log('当前点击',index)
            this.noteIndex=index
        },
        uploadSuccess(response, file, fileList){ // 上传成功
            console.log('上传成功',response,file,fileList)
            this.$message({
                type: 'success',
                message: "上传简历成功",
                duration: 2000
            });
            this.jobList[this.noteIndex].file=response.data
        },

        //  -------------------------接口请求
        getjobPostList(){ // 获取岗位列表
            jobPostList({}).then((res) => {
                if (res.data.code==200) {
                    this.jobList=res.data.data.records
                }else{

                }

            })
        },

        submitresume(item,index){ // 提交
            console.log('点击提交',item)
            if(!item.file){ // 未上传简历
                this.$message({
                    type: 'warning',
                    message: "未上传简历",
                    duration: 2000
                });
                return
            }
            let param={
                attachFileName:item.file.originalName,
                attachFileUrl:item.file.link,
                jobPostId:item.id
            }
            jobResumeSave(param).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: "提交成功",
                        duration: 2000
                    });
                }else{
                    this.$message({
                        type: 'success',
                        message: res.data.mag,
                        duration: 2000
                    });
                }

            })
        },

    },
}
</script>

<style scoped>

</style>
