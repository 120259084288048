<template>
    <div class="join">
<!--        <div class="" v-for="(item,index) in jobList" :key="index">-->
<!--            <h1>{{item.title}}</h1>-->
<!--            <br>-->
<!--            <p class="" v-html="`${item.describeText}`"></p>-->
<!--            <br>-->
<!--            <br>-->
<!--        </div>-->
        <div v-html="join_Us.contentText"></div>


<!--        <h1>技术支持(生物相关专业) 北京</h1>-->
<!--        <br>-->
<!--        <h3>职位职责:</h3>-->
<!--        <p>1、解答客户疑惑、向客户推荐合适的产品;</p>-->
<!--        <p>2、提供公司内部技术支持及培训工作;</p>-->
<!--        <p>3、配合销售部门完成销售指标。</p>-->
<!--        <br>-->
<!--        <br>-->
<!--        <h3>应聘要求:</h3>-->
<!--        <p>1、化学等相关专业背景，本科及以上学历;</p>-->
<!--        <p>2、有化学试剂行业经验优先;</p>-->
<!--        <p>3、有敬业精神、诚实可靠、团队意识强、沟通能力强;</p>-->
<!--        <p>4、英语良好，熟练使用计算机办公软件。</p>-->

        <div class="btn" @click="showJob = true">投递简历</div>
        <jobs v-if="showJob" @close="showJob = false"/>

    </div>
</template>

<script>
import jobs from "@/views/about/moudles/jobs.vue";
import {jobPostList} from "@/api/CMSmodule";
import {websiteConfigDetail} from "@/api/home";

export default {
    name: "join",
    components: {jobs},
    data() {
        return {
            showJob: false,
            jobList:[],
            join_Us:"", //加入我们
        }
    },
    mounted() {
        this.getwebsiteConfigDetail()
        this.getjobPostList()
    },
    methods:{
        //  -------------------------接口请求
        getwebsiteConfigDetail(){ // 获取基础设置 加入我们
            websiteConfigDetail({tag:'join_us'}).then((res) => {
                if (res.data.code==200) {
                    this.join_Us=res.data.data
                    this.join_Us.contentText=this.$utils.showHtml(this.join_Us.contentText)
                }else{

                }

            })
        },
        getjobPostList(){ // 获取岗位列表
            jobPostList({}).then((res) => {
                if (res.data.code==200) {
                    this.jobList=res.data.data.records
                    if(this.jobList.length>0){
                        this.jobList.forEach(i=>{
                            i.describeText=this.$utils.showHtml(i.describeText)
                        })
                    }
                }else{

                }

            })
        },

    },
}
</script>

<style scoped>

</style>
