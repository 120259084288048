<template>
    <div class="page-about">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>关于一方</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="g-tab-btn">
                <div class="item" @click="type = 1" :class="type === 1 ? 'active' : ''">关于一方</div>
                <div class="item" @click="type = 2" :class="type === 2 ? 'active' : ''">加入我们</div>
            </div>

            <about v-if="type === 1"/>
            <join v-if="type === 2"/>
        </div>
    </div>
</template>

<script>
import about from "@/views/about/moudles/about.vue";
import join from "@/views/about/moudles/join.vue";

export default {
    name: "About",
    components: {
        about, join
    },
    data() {
        return {
            type: 1
        }
    },
    mounted(){
        console.log(this.$route.query)
        if (this.$route.query.tab){
            this.type = this.$route.query.tab*1
        } else {
            this.type = 1
        }
        console.log(this.type)
    },
    watch:{
        '$route'(val){
            if (val.query.tab){
                this.type = val.query.tab*1
            } else {
                this.type = 1
            }
        }
    }
}
</script>

<style scoped>

</style>
